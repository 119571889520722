import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function Savings() {
  return (
    <Layout pageTitle="Savings">
      <SEO title="Savings" />
    </Layout>
  )
}

export default Savings
